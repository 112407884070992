"use strict";

var RendiliCore = {
  Json: {
    Get: function Get(parameters) {
      if (typeof parameters == 'string') {
        parameters = {
          url: parameters
        };
      }

      var promise = {
        onSuccess: null,
        onError: null,
        then: function then(success, error) {
          this.onSuccess = success;
          this.onError = error;
        }
      };
      $.ajax({
        type: 'GET',
        url: parameters.url,
        data: parameters.data,
        success: function success(data) {
          promise.onSuccess(data);
        },
        error: function error(xhr, status) {
          promise.onError(status);
        },
        dataType: 'json'
      });
      return promise;
    },
    Post: function Post(parameters) {
      if (typeof parameters == 'string') {
        parameters = {
          url: parameters
        };
      }

      var promise = {
        onSuccess: null,
        onError: null,
        then: function then(success, error) {
          this.onSuccess = success;
          this.onError = error;
        }
      };
      $.ajax({
        type: 'POST',
        url: parameters.url,
        data: parameters.data,
        success: function success(data) {
          promise.onSuccess(data);
        },
        error: function error(xhr, status) {
          promise.onError(status);
        },
        dataType: 'json'
      });
      return promise;
    },
    PostJsonData: function PostJsonData(parameters) {
      if (typeof parameters == 'string') {
        parameters = {
          url: parameters
        };
      }

      var promise = {
        onSuccess: null,
        onError: null,
        then: function then(success, error) {
          this.onSuccess = success;
          this.onError = error;
        }
      };
      $.ajax({
        type: 'POST',
        url: parameters.url,
        data: JSON.stringify(parameters.data),
        success: function success(data) {
          promise.onSuccess(data);
        },
        error: function error(xhr, status) {
          promise.onError(status);
        },
        dataType: 'json'
      });
      return promise;
    }
  },
  Raw: {
    Get: function Get(parameters) {
      if (typeof parameters == 'string') {
        parameters = {
          url: parameters
        };
      }

      var promise = {
        onSuccess: null,
        onError: null,
        then: function then(success, error) {
          this.onSuccess = success;
          this.onError = error;
        }
      };
      $.ajax({
        type: 'GET',
        url: parameters.url,
        data: parameters.data,
        success: function success(data) {
          promise.onSuccess(data);
        },
        error: function error(xhr, status) {
          promise.onError(status);
        },
        dataType: 'text'
      });
      return promise;
    }
  }
};